import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

const Menu = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  return (
    <>
      <nav className="px-4 py-2">
        {data.site.siteMetadata.menuLinks.map(link => (
          <Link
            to={link.link}
            className="flex justify-center text-sm leading-5 rounded-md font-medium text-gray-900 hover:text-red-800 hover:underline transition ease-in-out duration-150"
          >
            <span className="text-l sm:text-3xl">{link.name}</span>
          </Link>
        ))}
      </nav>
    </>
  )
}

export default Menu
