import React from "react"
import DefaultLayout from "../components/default-page-layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import logo from "../components/logo.svg"

export default function Aktuelles({ data }) {
  return (
    <>
      <DefaultLayout>
        <main>
          <div className="prose py-2">
            <h1> Aktuelles </h1>
          </div>
          {data.allGhostPost.edges.map(({ node }) => (
            <div className="relative lg:flex lg:items-center py-2">
              <div className="hidden lg:block lg:flex-shrink-0">
                {node.featureImageSharp ? (
                  <Img
                    fluid={node.featureImageSharp.childImageSharp.fluid}
                    className="w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full"
                  />
                ) : (
                  <img
                    className="w-32 h-32 flex-shrink-0 rounded-full"
                    src={logo}
                  />
                )}
              </div>
              <div className="relative lg:ml-10">
                <blockquote className="relative" />
                <Link to={"/posts/" + node.published_at + "/" + node.slug}>
                  <div className="text-2xl leading-9 font-medium text-gray-900">
                    {node.title}
                  </div>
                  {node.excerpt + " Mehr"}
                </Link>
              </div>
            </div>
          ))}
        </main>
      </DefaultLayout>
    </>
  )
}

export const postListingQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allGhostPost(
      sort: { fields: published_at, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          excerpt
          published_at(formatString: "YYYY/MM/DD")
          slug
          featureImageSharp {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
